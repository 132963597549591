declare global {
  interface UC_UI_Interface {
    isInitialized: () => boolean;
    showSecondLayer: () => void;
    closeCMP: () => Promise<void>;
  }

  interface Window {
    _rbCookieConsentOff: boolean | undefined;
    _rbHelpWidgetButtonOff: boolean | undefined;
    UC_UI: UC_UI_Interface | undefined;
    __ucCmp?: UsercentricsCmp;
    Cypress?: {
      env?: any;
    };
  }
}

export type UsercentricsCallback = (e?: Event) => void;

export interface UsercentricsServiceConsentItem {
  id: string;
  consent: boolean;
}

export interface UsercentricsCallbackRecordItem {
  id: string;
  callback: UsercentricsCallback;
  checkExplicit?: boolean;
  forceCallback?: boolean;
}

export interface UsercentricsCmp {
  cmpController: UsercentricsController;
  updateServicesConsents: (
    e: UsercentricsServiceConsentItem[]
  ) => Promise<void>;
  saveConsents: () => Promise<void>;
}

export interface UsercentricsController {
  dps: {
    services: {
      [key: string]: UsercentricsService;
    };
  };
}

export interface UsercentricsService {
  category: string;
  consent: {
    given: boolean;
    type: string;
  };
  essential: boolean;
  name: string;
  version: string;
}

export interface UsercentricsServiceItem extends UsercentricsService {
  id: string;
}

export enum USERCENTRICS_EVENTS {
  initialized = 'UC_UI_INITIALIZED',
  updated = 'uc_consent_status',
}

const USERCENTRICS_CALLBACK_RECORD_ITEMS: UsercentricsCallbackRecordItem[] = [];

export function getServicesFullInfo() {
  const servicesRaw = window.__ucCmp?.cmpController?.dps?.services;

  if (!servicesRaw) {
    return;
  }

  return Object.entries(servicesRaw).map(([key, value]) => {
    return {
      id: key,
      ...value,
    } as UsercentricsServiceItem;
  });
}

export async function getService(
  id: string,
  cachedServicesFullInfo?: UsercentricsServiceItem[]
) {
  const isInitialized = window.UC_UI?.isInitialized();

  if (!isInitialized) {
    return;
  }

  const services = cachedServicesFullInfo ?? getServicesFullInfo();

  if (!services) {
    return;
  }

  return services.find((item) => item.name === id || item.id === id);
}

export async function isServiceAccepted(
  id: string,
  checkExplicit = true,
  cachedServicesFullInfo?: UsercentricsServiceItem[]
) {
  let value: boolean | null = null;

  const service = await getService(id, cachedServicesFullInfo);

  if (service) {
    if (checkExplicit) {
      if (service.consent) {
        value =
          service.consent.given &&
          service.consent.type.toLowerCase() === 'explicit';
      }
    } else {
      value = service.consent.given;
    }
  }

  return value;
}

async function onUsercentricsStatusUpdated() {
  const services = getServicesFullInfo();

  if (!services) {
    return;
  }

  for (let i = USERCENTRICS_CALLBACK_RECORD_ITEMS.length - 1; i >= 0; i--) {
    const item = USERCENTRICS_CALLBACK_RECORD_ITEMS[i];

    const consentStatus = await isServiceAccepted(
      item.id,
      item.checkExplicit,
      services
    );

    if (consentStatus) {
      USERCENTRICS_CALLBACK_RECORD_ITEMS.splice(i, 1);

      if (item.forceCallback) {
        item.callback();
      }
    }
  }

  if (!USERCENTRICS_CALLBACK_RECORD_ITEMS.length) {
    window.removeEventListener(
      USERCENTRICS_EVENTS.initialized,
      onUsercentricsStatusUpdated
    );
    window.removeEventListener(
      USERCENTRICS_EVENTS.updated,
      onUsercentricsStatusUpdated
    );
  }
}

export async function executeCallbackWhenServiceIsAccepted(
  id: string,
  callback: UsercentricsCallback,
  checkExplicit = true,
  forceCallback?: boolean
) {
  const force = forceCallback ?? isUsercentricsEnabled();

  if (!isUsercentricsEnabled() || (await isServiceAccepted(id))) {
    if (force) {
      callback();
    }
  } else {
    window.removeEventListener(
      USERCENTRICS_EVENTS.initialized,
      onUsercentricsStatusUpdated
    );
    window.removeEventListener(
      USERCENTRICS_EVENTS.updated,
      onUsercentricsStatusUpdated
    );

    USERCENTRICS_CALLBACK_RECORD_ITEMS.unshift({
      id,
      callback,
      checkExplicit,
      forceCallback: force,
    });

    window.addEventListener(
      USERCENTRICS_EVENTS.initialized,
      onUsercentricsStatusUpdated
    );
    window.addEventListener(
      USERCENTRICS_EVENTS.updated,
      onUsercentricsStatusUpdated
    );
  }
}

export function isUsercentricsEnabled(resolve?: any, reject?: any) {
  const _rbCookieConsentOff =
    window._rbCookieConsentOff ??
    (typeof window.Cypress !== 'undefined'
      ? window.Cypress?.env('_rbCookieConsentOff')
      : false);

  if (_rbCookieConsentOff) {
    if (typeof reject === 'function') {
      return reject();
    } else if (reject !== undefined) {
      return reject;
    } else {
      return false;
    }
  } else {
    if (typeof resolve === 'function') {
      return resolve();
    } else if (resolve !== undefined) {
      return resolve;
    } else {
      return true;
    }
  }
}

export async function acceptService(id: string): Promise<void> {
  const service = await getService(id);

  if (service) {
    window.__ucCmp?.updateServicesConsents([
      {
        id: service.id,
        consent: true,
      },
    ]);

    window.__ucCmp?.saveConsents();
  }
}

export async function showService(id: string): Promise<void> {
  const service = await getService(id);

  if (service) {
    window.UC_UI?.showSecondLayer();

    const iframe = await waitForQuerySelector('#usercentrics-cmp-ui');

    if(!iframe?.shadowRoot) return;

    const cmp = await waitForQuerySelector('#uc-main-dialog', iframe.shadowRoot);

    if(!cmp) return;

    const categoryButton = (await waitForQuerySelector(
      `#uc-category-${service.category} [role="button"].list-item-header-expander`,
      cmp
    )) as HTMLElement | null;

    if (!categoryButton) return;

    if (categoryButton.getAttribute('aria-expanded') !== 'true') {
      categoryButton.click();
    }

    const serviceButton = (await waitForQuerySelector(
      `#service-icon-${service.id}`,
      cmp
    )) as HTMLElement | null;

    if (!serviceButton) return;

    serviceButton.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });

    const wrapper = serviceButton.closest('.uc-expandable-card.list-item') as HTMLElement | null;

    if (!wrapper) return;

    wrapper.style.backgroundColor = 'var(--color-buttons-accept-background)';

    const removeBackground = () => {
        wrapper.style.backgroundColor = '';

        document.removeEventListener('mousedown', removeBackground);
        document.removeEventListener('keydown', removeBackground);
    }

    document.addEventListener('mousedown', removeBackground, { once: true });
    document.addEventListener('keydown', removeBackground, { once: true });
  }
}

export function waitForQuerySelector(
  selector: string,
  parent?: Element | ShadowRoot,
  attempts = 10000,
  interval = 50
): Promise<Element | null> {
  return new Promise(function (resolve) {
    const checkElement = () => {
      if (attempts > 0) {
        const element = (parent ?? document).querySelector(selector);

        if (element) {
          resolve(element);
        } else {
          attempts--;
          setTimeout(checkElement, interval);
        }
      } else {
        resolve(null);
      }
    };

    checkElement();
  });
}
